export default class API {
  static loginAPI: string =
    "https://generaliifrs.aisolutions.technology/api/user_management/login";
  static dialogFlowAPI: string =
    "https://generaliifrs.aisolutions.technology/chatbot/es";
  //   static loginAPI: string =
  //   "https://generali.aisolutions.technology/api/user_management/login";
  // static dialogFlowAPI: string =
  //   "https://generali.aisolutions.technology/chatbot/es";
}
