import React, { ReactElement, useEffect, useState } from "react";
import Conversation from "./components/chat/Conversation";
import LoginTeams from "./components/LoginTeams";
import * as microsoftTeams from "@microsoft/teams-js";
import { Box, Button, Flex, Heading } from "@chakra-ui/react";

interface UserCredentials {
  email: string;
  identifier: string;
}

function noAuthentication() {
  console.log("[noAuthentication]");

  return (
    <div>
      <Flex
        width="full"
        align="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Box
          bg="white"
          p={8}
          maxWidth="500px"
          borderWidth={1}
          borderRadius={8}
          boxShadow="lg"
          w="50%"
          m={4}
          color="black"
        >
          <Box textAlign="center" color="red.500">
            <Heading>Not Authenticated {Date.now()} </Heading>
          </Box>
        </Box>
      </Flex>
    </div>
  );
}
function App(): ReactElement {
  const [loggedInTeams, setLoggedInTeams] = useState(false);
  const [userCredentials, setUserCredentials] =
    useState<UserCredentials | undefined>();

  const loginTeams = async () => {
    console.log("Inizio verifica... Siamo o no su Teams?");

    microsoftTeams.initialize();
    microsoftTeams.getContext((context: any) => {
      setUserCredentials({ email: context.loginHint, identifier: context.channelId });
      console.log(
        "[loggedInTeams] Credenziali salvate per l'utente: ",
        context.loginHint
      );
      console.log("teamId ",context.teamId , " channelId ", context.channelId ," chatId", context.chatId ," groupId", context.groupId)
    });
  };

  
  useEffect(() => {
    loginTeams();
    return () => {};
  }, []);

  useEffect(() => {
    if (userCredentials && !loggedInTeams) setLoggedInTeams(true);

    return () => {};
  }, [userCredentials]);

  return loggedInTeams ? (
    <LoginTeams
      loggedInTeams={loggedInTeams}
      UserCredentials={userCredentials}
      noAuthentication={() => noAuthentication()}
    >
      <Conversation></Conversation>
    </LoginTeams>
  ) : (
    noAuthentication()
  );
}

export default App;
export type { UserCredentials };
