import { Console } from "console";

interface IMessage {
  body: any;
  type: MessageType;
  isBot: boolean;
}
enum MessageType {
  TEXT,
  PAYLOAD,
  ERROR,
}

abstract class Message implements IMessage {
  body: any;
  type: MessageType;
  isBot: boolean;
  protected constructor(body: any, type: MessageType, isBot: boolean) {
    this.body = body;
    this.type = type;
    this.isBot = isBot;
  }
}
class BotMessage extends Message implements IMessage {
  intent: any;
  constructor(body: any, type: MessageType) {
    super(body, type, true);
    this.intent = body.intent;
  }
}
class TextBotMessage extends BotMessage {
  private _text: string;
  constructor(body: any) {
    super(body, MessageType.TEXT);
    this._text = body.text.text[0];
  }
  get text() {
    return this._text;
  }
}
class PayloadBotMessage extends BotMessage {
  private _content: any;
  private _payload: string;
  private _values: any;
  private _endSession: boolean;
  private _list: any;
  private _list_video_name: any;
  private _rating: boolean;
  private _folder_list: any;

  constructor(body: any) {
    super(body, MessageType.PAYLOAD);
    const payload = body[body.message];
    this._content = payload.fields.richContent[
      payload.fields.richContent.kind
    ].values.map((v: any) =>
      v[v.kind].values.map((x: any) => x[x.kind].fields)
    )[0][0];
    this._payload = this._content.type[this._content.type.kind];
    this._endSession = false;

    if (body.diagnosticInfo) {
      const executionSequence =
        body.diagnosticInfo.fields["Execution Sequence"];
      this._endSession =
        executionSequence[executionSequence.kind].values
          .map((v: any) => {
            const step = v[v.kind].fields[Object.keys(v[v.kind].fields)[0]];
            return step && step[step.kind].fields;
          })
          .filter((f: any) => {
            const isStateMachine: boolean =
              f.Type && f.Type[f.Type.kind] === "STATE_MACHINE";
            if (isStateMachine) {
              const fields = f.StateMachine[f.StateMachine.kind].fields;
              return (
                fields.TargetPage &&
                fields.TargetPage[fields.TargetPage.kind] === "END_SESSION"
              );
            }
            return false;
          }).length > 0;
    }

    this._list = [{}];
    if (this._payload === "videos") {
      const values =
        body.payload.fields.richContent[body.payload.fields.richContent.kind]
          .values[0];
      const options =
        values[values.kind].values[0][values[values.kind].values[0].kind].fields
          .options;

      const list = options[options.kind].values.map((v: any) => {
        var name =
          v.structValue.fields.VideoName[v.structValue.fields.VideoName.kind];
        var score = v.structValue.fields.Score[v.structValue.fields.Score.kind];
        var abstract =
          v.structValue.fields.Abstract[v.structValue.fields.Abstract.kind];
        var link = v.structValue.fields.link[v.structValue.fields.link.kind];
        var details = v.structValue.fields.Result[
          v.structValue.fields.Result.kind
        ].values.map((v: any) => {
          var highlight =
            v[v.kind].fields.Highlight[v[v.kind].fields.Highlight.kind];
          var EndTime = v[v.kind].fields.EndTime[v[v.kind].fields.EndTime.kind];
          var StartTime =
            v[v.kind].fields.StartTime[v[v.kind].fields.StartTime.kind];
          return { highlight, EndTime, StartTime };
        });
        return { name, abstract, score, details, link};
      });

      this._list = list;
    }


    this._list_video_name = [{}];
    if (this._payload === "video_list") {
      const values =
        body.payload.fields.richContent[body.payload.fields.richContent.kind]
          .values[0];
          var title = values[values.kind].values[0][values[values.kind].values[0].kind].fields.title;
          console.log("titolo ", title)
      const options =
        values[values.kind].values[0][values[values.kind].values[0].kind].fields
          .options;
      const list = options[options.kind].values.map((v: any) => {
        var name = v[v.kind].fields.title[v.structValue.fields.title.kind];
        var link = v[v.kind].fields.link[v.structValue.fields.link.kind];
        var path = v[v.kind].fields.path[v.structValue.fields.path.kind]; 

        /* Da eliminare nel caso i tag non siano più in scope

        var tags = v[v.kind].fields.tags[
          v.structValue.fields.tags.kind
        ].values.map((v: any) => {
          var tag = v[v.kind];
          return { tag };
        });

return { name, subtitle, title, link, tags };
        */
       
      return { name, title, link, path};
      })
   
      var sortedArray = list.sort(function (a: any, b:any) {
        if (a.name < b.name) return -1;
        else if (a.name > b.name) return 1;
        return 0;
      });

      this._list_video_name = sortedArray;
    }


      this._folder_list = [{}];
    if (this._payload === "folder_list") {
      const values =
        body.payload.fields.richContent[body.payload.fields.richContent.kind]
          .values[0];
          var title = values[values.kind].values[0][values[values.kind].values[0].kind].fields.title;
      const options =
        values[values.kind].values[0][values[values.kind].values[0].kind].fields
          .options;
      const list = options[options.kind].values.map((v: any) => {
        
        var link = v[v.kind].fields.link[v.structValue.fields.link.kind];
        var title = v[v.kind].fields.title[v.structValue.fields.title.kind];
    
      return { title, link};
      })
      this._folder_list = list;
    }



    this._values =
      this._payload === "chips" || this._payload === "multichoice"
        ? this._content.options[this._content.options.kind].values.map(
            (v: any) => v[v.kind].fields.text[v[v.kind].fields.text.kind]
          )
        : [];

    this._rating =
      this._payload === "chips" &&
      (body.intent.displayName === "terminates" ||
        body.intent.displayName === "terminates - fallback")
        ? true
        : false;

}

  get content() {
    return this._content;
  }
  get payload() {
    return this._payload;
  }
  get values() {
    return this._values;
  }
  get endSession() {
    return this._endSession;
  }

  get list() {
    return this._list;
  }

  get list_video_name() {
    return this._list_video_name;
  }

  get rating() {
    return this._rating;
  }

  get folder_list() {
    return this._folder_list;
  }
}

class UserMessage extends Message implements IMessage {
  constructor(body: any, type: MessageType) {
    super(body, type, false);
  }
}
class TextUserMessage extends UserMessage {
  private _text: string;
  constructor(body: any) {
    super(body, MessageType.TEXT);
    this._text = body.text.text[0];
  }
  get text() {
    return this._text;
  }
}
export {
  MessageType,
  Message,
  BotMessage,
  UserMessage,
  TextBotMessage,
  TextUserMessage,
  PayloadBotMessage,
};
