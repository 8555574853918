import { SlideFade } from "@chakra-ui/react";
import React, { ReactElement, useState, useEffect } from "react";
import ErrorBotMsg from "./messages/ErrorBotMsg";
import PayloadBotMsg from "./messages/PayloadBotMsg";
import TextBotMsg from "./messages/TextBotMsg";
import TextUserMsg from "./messages/TextUserMsg";
import { MessageType } from "./models/Messages";

interface Props {
  msg: any;
  onPayload: any;
}
function messageType(msg: any, onPayload: any) {
  switch (msg.type) {
    case MessageType.TEXT:
      return msg.isBot ? <TextBotMsg msg={msg} /> : <TextUserMsg msg={msg} />;
    case MessageType.PAYLOAD:
      return <PayloadBotMsg msg={msg} onPayload={onPayload} />;
    default:
      return <ErrorBotMsg msg={msg}></ErrorBotMsg>;
  }
}
function Chat({ msg, onPayload }: Props): ReactElement {
  const [loaded, setLoaded] = useState<boolean>(false);
  useEffect(()=>{
    setTimeout(()=>{
    setLoaded(true)
  },msg.isBot?1000:0)
  })  
  return (

    <SlideFade  offsetY="20px" in={loaded}>
      {messageType(msg, onPayload)}
    </SlideFade>
  );
}

export default Chat;
