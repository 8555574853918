import axios from "axios";
import React, { ReactElement, useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { BiSleepy } from "react-icons/bi";
import { UserCredentials } from "../App";
import { format } from "util";
import API from "../config/api-config";

interface Props {
  children?: any;
  loggedInTeams: boolean;
  UserCredentials: UserCredentials | undefined;
  noAuthentication: () => any;
}

interface AuthJWT {
  role: any;
  email: any;
  name: any;
  family_name: any;
  display_name: any;
  signature: any;
  expirationDate: number;
  identity: any;
}

const authenticate =  (
  UserCredentials: UserCredentials | undefined,
  loggedInTeams: boolean,
  token: string | undefined,
  onToken?: (jwt: string) => void
) => {
  if (loggedInTeams) {
    console.log("[authenticate] Sei su Teams");
    let jwtInStorage = localStorage.getItem("jwt");
    if (!jwtInStorage) {
      console.log(
        "[authenticate] Token non presente, chiamata alla funzione callTokenAPI in corso"
      );
       callTokenAPI(UserCredentials, onToken);
    } else {
      console.log("[authenticate] Token presente, verifica della sua validità");
      let user: AuthJWT = parseJwt(jwtInStorage!);
      let now = new Date().getTime() / 1000;

      const last_timestamp = localStorage.getItem(
        "last_timestamp"
      ) as unknown as number;

      const check = (Date.now() - last_timestamp) / 1000;

      if (user && user.expirationDate > now && check < 900) {
        //METTERE 900 secondi, 15 minuti.
        console.log("[authenticate] Token presente e valido");
        const last_timestamp = Date.now() as unknown as string;
        localStorage.setItem("last_timestamp", last_timestamp);
        if (typeof token === "undefined") {
          if (onToken) onToken(jwtInStorage as string);
        }
      } else {
        console.log(
          "[authenticate] Sei su teams ma token e/o sessione scaduta "
        );
        localStorage.removeItem("jwt");
        localStorage.removeItem("last_timestamp");
        authenticate(UserCredentials, loggedInTeams, token, onToken);
      }
    }
  } else {
    console.log("[authenticate] Non sei su teams");
  }
};

const callTokenAPI = async (
  UserCredentials: UserCredentials | undefined,
  onToken?: (jwt: string) => void
) => {
  console.log("[callTokenAPI]: credenziali ", UserCredentials);
  if (UserCredentials) {
    await axios
      .post(
        API.loginAPI,
        // "https://generalinext.aisolutions.technology/api/user_management/login",
        //"https://generalinext.aisolutions.technology/test/api/user_management/login", //PER TEST
        UserCredentials,
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((response) => {
        console.log("[callTokenAPI] response: ", response);
        let authToken: string = response.data.access_token;
        if (!authToken) {
          console.log(
            "[callTokenAPI] Utente non autenticato, nessun token restituito."
          );
        } else {
          localStorage.setItem("jwt", authToken);
          const last_timestamp = Date.now() as unknown as string;
          localStorage.setItem("last_timestamp", last_timestamp);

          console.log("[callTokenAPI] token salvato");
          if (onToken) onToken(authToken as string);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } else {
    console.log("[callTokenAPI] Credenziali non presenti");
  }
};

const parseJwt = (token: string): AuthJWT => {
  if (!token) {
    //Cambiare la seguente struttura in funzione della futura struttura del token
    return {
      email: "",
      name: "",
      family_name: "",
      display_name: "",
      role: "",
      signature: "",
      expirationDate: 0,
      identity: "",
    };
  }
  let base64Url = token.toString().split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  let parsedToken = JSON.parse(jsonPayload);
 
  //Provvisoria, da definire il contenuto dei seguenti campi
  let parsed = {
    role: "dev",
    email: parsedToken.sub,
    name: "dev_name",
    family_name: "Dev_family",
    display_name: "name",
    signature: "signature",
    expirationDate: parsedToken.exp,
    identity: parsedToken.sub,
  };

  return parsed;
};

function LoginTeams({
  children,
  UserCredentials,
  loggedInTeams,
  noAuthentication = () => null,
}: Props): ReactElement {
  const [token, setToken] = useState<string>(
    localStorage.getItem("jwt") as string
  );


  console.log("[LoginTeams] Start");

  useEffect(() => {
    if (token) {
      console.log("[LoginTeams] UseEffect Token");
    } else {
      authenticate(UserCredentials, loggedInTeams, token, (jwt) =>
        setToken(jwt)
      );
    }
    return () => {};
  }, [token]);
  const childrenWithProps = React.Children.map(children, (child) => {
    // checking isValidElement is the safe way and avoids a typescript error too
    if (React.isValidElement(child)) {
      return token ? React.cloneElement(child) : noAuthentication();
    }
    return child;
  });

  return childrenWithProps;
}

export { authenticate };
export default LoginTeams;
