import { Badge, Box, Flex, IconButton, Spacer, Text } from "@chakra-ui/react";
import React, { ReactElement, useState } from "react";
import { BiBot, BiHelpCircle } from "react-icons/bi";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { TextBotMessage } from "../models/Messages";

interface Props {
  msg: TextBotMessage;
}

function getStringAfterSubstring(text: string, keyWord: string) {
  return text.substring(text.indexOf(keyWord) + keyWord.length);
}

function getStringBeforeSubstring(text: string, keyWord: string) {
  return text.substring(0, text.indexOf(keyWord));
}

function insertIcon(text: string) {
  let finalMsg: string[] = [];

  if (
    text.includes("question_mark_react") ||
    text.includes("quit_button_react")
  ) {
    if (
      text.indexOf("question_mark_react") === -1 ||
      text.indexOf("quit_button_react") === -1
    ) {
      var flag = text.indexOf("question_mark_react") === -1;
      const p1 = getStringBeforeSubstring(
        text,
        flag ? "quit_button_react" : "question_mark_react"
      );
      const p2 = getStringAfterSubstring(
        text,
        flag ? "quit_button_react" : "question_mark_react"
      );
      finalMsg = [p1, flag ? "quit_button_react" : "question_mark_react", p2];
    } else {
      if (
        getStringBeforeSubstring(text, "question_mark_react").includes(
          "quit_button_react"
        )
      ) {
        const p1 = getStringBeforeSubstring(text, "quit_button_react");
        const p2 = getStringAfterSubstring(
          getStringBeforeSubstring(text, "question_mark_react"),
          "quit_button_react"
        );
        const p3 = getStringAfterSubstring(text, "question_mark_react");
        finalMsg = [p1, "quit_button_react", p2, "question_mark_react", p3];
      } else {
        const p1 = getStringBeforeSubstring(text, "question_mark_react");
        const p2 = getStringAfterSubstring(
          getStringBeforeSubstring(text, "quit_button_react"),
          "question_mark_react"
        );
        const p3 = getStringAfterSubstring(text, "quit_button_react");
        finalMsg = [p1, "question_mark_react", p2, "quit_button_react", p3];
      }
    }
  }

  return finalMsg;
}

function TextBotMsg({ msg }: Props): ReactElement {
  console.log(msg);

  const botName = "Atena"
  var finalMsg = insertIcon(msg.text);
  const containsIcon = finalMsg.length === 0;

  const isHelp =
    msg.intent &&
    msg.intent.displayName &&
    msg.intent.displayName.startsWith("help-");

  const fontSize = msg.intent.displayName === "initial_welcome" ? "17px" : "md";
  const sizeBot =
    msg.intent.displayName === "initial_welcome" ? "24px" : "22px";
  const backgroundColor =
    msg.intent.displayName === "initial_welcome" || msg.intent.displayName === "session_expired"
      ? "white"
      : isHelp
      ? "#dedede"
      : "#e8e8e8";
  const textColor =
    msg.intent.displayName === "initial_welcome" || msg.intent.displayName === "session_expired" ? "red.500" : "#4f4e4e";
  const fontWeight =
    msg.intent.displayName === "initial_welcome" ? "semibold" : "normal";
  const fontFamily =
    msg.intent.displayName === "initial_welcome" ? "heading" : "body"; 
    const fontStyle =
    msg.intent.displayName === "initial_welcome" ? "italic" : "body";

  return (
    <Flex p="1">
      <Box
        p="2"
        maxW="lg"
        mr="4"
        borderWidth="1px"
        borderColor="red.500"
        backgroundColor={backgroundColor}
        borderTopRightRadius="2xl"
        borderBottomLeftRadius="2xl"
        borderBottomRightRadius="lg"
        overflow="hidden"
        d="flex"
        alignItems="baseline"
      >
        <Text as="span" top="4px" position="relative" color={textColor}>
          <BiBot color="#4f4e4e" size={sizeBot}></BiBot>
        </Text>
        <Text
          as="span"
          fontSize={fontSize}
          fontWeight="bold"
          // color={textColor} //red
          color="#4f4e4e" //grigio
        >
          Atena:&nbsp;
        </Text>
        <Text
          as="span"
          fontSize={fontSize}
          fontWeight={fontWeight}
          color={textColor}
          fontFamily={fontFamily}
          fontStyle={fontStyle}
        >
          {isHelp ? (
            <Box as="span">
              <Badge as="span" mb="1" variant="solid" colorScheme="red">
                <Box as="span" d="flex">
                  <BiHelpCircle size="16" />
                  &nbsp;Help
                </Box>
              </Badge>
              &nbsp;
            </Box>
          ) : null}{" "}
          {containsIcon
            ? msg.text
            : finalMsg.map((x: string) => {
                if (x === "question_mark_react") {
                  return (
                    <Badge borderRadius="md" as="span" variant="solid"  mb="1" colorScheme="red">
                      <Box as="span" d="flex"  marginBlock="1" alignItems="center">
                        {" "}
                        <BiHelpCircle  size="18" />{" "}
                      </Box>{" "}
                    </Badge>
                    
                  );
                } else if (x === "quit_button_react") {
                  return (
                    <Badge borderRadius="md" as="span" variant="solid" mb="1" colorScheme="red">
                      <Box as="span" d="flex"  marginBlock="1" alignItems="center">
                        {" "}
                        <IoMdCloseCircleOutline size="18" />{" "}
                      </Box>{" "}
                    </Badge>
                  );
                } else {
                  return x;
                }
              })}
        </Text>
      </Box>
      <Spacer />
    </Flex>
  );
}

export default TextBotMsg;
