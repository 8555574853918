import {
  Accordion as ChakraAccordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  IconButton,
  Spacer,
  Stack,
} from "@chakra-ui/react";
import React, { ReactElement, useState, useEffect } from "react";
import jsPdf from "jspdf";
import { BiDownload } from "react-icons/bi";
import { array, number } from "prop-types";
import { HiExternalLink } from "react-icons/hi";
import RatingBotMsg from "../../RatingBotMsg";
//import { Tooltip } from "@material-ui/core";
//import { makeStyles } from "@material-ui/core/styles";

interface Props {
  msg: any;
  onPayload: any;
}
interface ChipProps {
  choice?: string;
  value?: string[];
  onPayload: any;
}
interface AccordionProps {
  msg: any;
  title: string;
  list: string[];
  onPayload: any;
}

function Accordion({
  msg,
  title,
  list,
  onPayload,
}: AccordionProps): ReactElement {
  const [choice, setChoice] = useState<string>("");
  const pdfRef = React.createRef<HTMLDivElement>();

  const choose = () => {
    onPayload(choice);
  };

  const printPDF = () => {
    const filename = msg.content.title[msg.content.title.kind];
    const content = pdfRef.current?.innerHTML;
    const container = document.createElement("div");

    container.id = "pdf-container";
    container.style.display = "none";

    if (content)
      container.innerHTML =
        "<h1 style='color:red; border-bottom: 2px solid black;'>"
          .concat(filename)
          .concat("</h1>")
          .concat(content);

    document.body.appendChild(container);
    const pdf = new jsPdf();
    pdf.setFont("helvetica", "italic");
    const data = document.getElementById("pdf-container");
    if (data) pdf.fromHTML(data, 10, 10);
    pdf.save(filename + ".pdf");
    document.getElementById("pdf-container")?.remove();
  };

  return (
    <Box
      p="2"
      mt="-5"
      maxW="lg"
      borderWidth="1px"
      backgroundColor="white"
      borderColor="red.200"
      borderRadius="lg"
      overflow="hidden"
    >
      <Flex>
        {msg.endSession ? (
          <IconButton
            colorScheme="red"
            aria-label="Search database"
            onClick={printPDF}
            size="xs"
            mt="3"
            ml="2"
            variant="outline"
            icon={<BiDownload size="1.2em" />}
          />
        ) : null}
        <ChakraAccordion
          defaultIndex={msg.endSession ? 0 : 1}
          allowToggle
          borderColor="transparent"
          backgroundColor="white"
        >
          <AccordionItem backgroundColor="white">
            <AccordionButton
              as="div"
              color="white"
              _hover={{ color: "white", border: "none" }}
            >
              <Box
                width={msg.endSession ? "17rem" : "19rem"}
                flex="1"
                textAlign="left"
                fontSize="xl"
                fontWeight="bold"
                backgroundColor="white"
                color="red.500"
              >
                {msg.content.title[msg.content.title.kind]}
              </Box>
              <a title="Click here to collapse or open the list!">
                {" "}
                <AccordionIcon color="red.500" mr="3" />
              </a>
            </AccordionButton>
            <AccordionPanel p={7} backgroundColor="white">
              <Box
                ref={pdfRef}
                dangerouslySetInnerHTML={{
                  __html: msg.content.text[msg.content.text.kind],
                }}
              ></Box>
            </AccordionPanel>
          </AccordionItem>
        </ChakraAccordion>
      </Flex>
    </Box>
  );
}

function SearchKeyword({
  msg,
  title,
  list,
  onPayload,
}: AccordionProps): ReactElement {
  const [choice, setChoice] = useState<string>("");
  const pdfRef = React.createRef<HTMLDivElement>();

  const choose = () => {
    onPayload(choice);
  };

  /* Per il tooltip personalizzato
 
 
 const useStyles = makeStyles((theme) => ({
    tooltip: {
      backgroundColor: "#DEDEDE",
      color: "#BB0000",
      width: "92px",
      height: "relative",
      borderRadius: "5px",
      boxShadow: "1px",
    },
    arrow: {
      fontSize: 16,
      width: 17,
      "&::before": {
        backgroundColor: "#DEDEDE",
        boxSizing: "border-box",
      },
    },
  }));
  const classes = useStyles();

  */

  /* Stampa PDF dei Result video. Da implementare correttamente successivamente
  
  const printPDF = () => {
    const filename = "Results";
    const content = pdfRef.current?.innerHTML;
    const container = document.createElement("div");

    container.id = "pdf-container";
    container.style.display = "none";

    if (content)
      container.innerHTML = "<h1 style='color:red; border-bottom: 2px solid black;'>"
        .concat(filename)
        .concat("</h1>")
        .concat(content);

    document.body.appendChild(container);
    const pdf = new jsPdf({
      orientation: "p",
      unit: "mm",
      format: "a4",
      putOnlyUsedFonts: true,
      floatPrecision: 16,
      pagesplit: true,
      width: 550
    });

    pdf.setFont("helvetica", "italic");
    const data = document.getElementById("pdf-container");
    if (data) pdf.fromHTML(data, 10, 10);
    pdf.save(filename + ".pdf");
    document.getElementById("pdf-container")?.remove();
  };

  */

  return (
    <Box
      p="2"
      mt="-5"
      maxW="xl"
      borderWidth="1px"
      backgroundColor="white"
      borderColor="red.200"
      borderRadius="lg"
      overflow="hidden"
    >
      <Flex>
        {false ? (
          <IconButton
            colorScheme="red"
            aria-label="Search database"
            // onClick={printPDF} da attivare quando verrà implementata la funzione. Cambiare true/false iniziale se si vuole o meno stampare il pdf
            size="xs"
            mt="3"
            ml="2"
            variant="outline"
            icon={<BiDownload size="1.2em" />}
          />
        ) : null}
        <ChakraAccordion
          defaultIndex={0}
          allowToggle
          borderColor="transparent"
          backgroundColor="white"
        >
          <AccordionItem backgroundColor="white">
            <AccordionButton
              as="div"
              color="white"
              _hover={{ color: "white", border: "none" }}
            >
              <Box
                width={"19rem"}
                flex="1"
                textAlign="left"
                fontSize="xl"
                fontWeight="bold"
                backgroundColor="white"
                color="red.500"
              >
                Results
              </Box>
              <a title="Click here to collapse or open the list!">
                {" "}
                <AccordionIcon color="red.500" mr="3" />
              </a>
            </AccordionButton>
            <AccordionPanel p={7} backgroundColor="white">
              <Box ref={pdfRef}>
                {msg.list.map((video: any, index: number) => {
                  console.log(video)
                  return (
                    <Box>
                      <Box
                        _hover={{ textDecorationLine: "Underline" }}
                        marginLeft="-3"
                        color="red.500"
                      >
                        <a
                          title="Click here to open the video!"
                          href={video.link}
                          target="_blank"
                        >
                          {" "}
                          <IconButton
                            colorScheme="red.400"
                            aria-label="Search database"
                            size="xs"
                            _focus={{
                              outline: "none",
                            }}
                            ml="2"
                            variant="outline"
                            icon={<HiExternalLink size="1.2em" />}
                          />{" "}
                          <b> {video.name}</b>{" "}
                        </a>
                      </Box>
                      <Box color="black" marginBottom="2" fontSize="85%">
                        {video.details.map((details: any) => {
                          return (
                            <Box color="black" marginBottom="2">
                              <Box>
                                <b>Highlight: </b>
                                <Box
                                  as="span"
                                  dangerouslySetInnerHTML={{
                                    __html: details.highlight
                                      .split("<em>")
                                      .join(
                                        '<span style="background-color: #FB7777">'
                                      )
                                      .split("</em>")
                                      .join("</span>"),
                                  }}
                                ></Box>
                              </Box>
                              <Box>
                                <b>Start: </b> {details.StartTime},{" "}
                                <b> End: </b> {details.EndTime}
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                      <Box marginTop="3" marginBottom="3">
                        <hr />
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </ChakraAccordion>
      </Flex>
    </Box>
  );
}

function MultiChoice({
  msg,
  title,
  list,
  onPayload,
}: AccordionProps): ReactElement {
  const [choice, setChoice] = useState<string>("");
  const [items, setItems] = useState<Array<string>>([]);
  const onCheck = (e: any) => {
    if (e.target.checked) {
      setItems([...items, e.target.value]);
    } else {
      setItems(items.filter((item) => item !== e.target.value));
    }
  };
  useEffect(() => {
    setChoice(items.join(" "));
  });
  const choose = () => {
    onPayload(choice);
  };

  return (
    <Box
      p="2"
      mt="-5"
      borderWidth="1px"
      backgroundColor="white"
      borderColor="red.200"
      borderRadius="lg"
      overflow="hidden"
    >
      <ChakraAccordion
        allowToggle
        borderColor="transparent"
        backgroundColor="white"
      >
        <AccordionItem minW="16vw" backgroundColor="white">
          <AccordionButton
            as="div"
            color="white"
            _hover={{ color: "white", border: "none" }}
          >
            <Box
              flex="1"
              textAlign="left"
              fontSize="xl"
              fontWeight="bold"
              backgroundColor="white"
              color="red.500"
            >
              {msg.content?.title
                ? msg.content.title[msg.content.title.kind]
                : null}
            </Box>
            <a title="Click here to collapse or open the list!">
              {" "}
              <AccordionIcon color="red.500" mr="3" />
            </a>
          </AccordionButton>
          <AccordionPanel p={7} backgroundColor="white">
            <Stack maxWidth="100%">
              <Box>
                <CheckboxGroup colorScheme="red" defaultValue={[]}>
                  <Stack>
                    {msg.values.map((choice: string, i: number) => (
                      <Checkbox value={choice} onChange={onCheck}>
                        {choice}
                      </Checkbox>
                    ))}
                  </Stack>
                </CheckboxGroup>
              </Box>
              <Box textAlign="left" pt="5">
                <Button colorScheme="red" size="sm" onClick={choose}>
                  Confirm
                </Button>
              </Box>
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </ChakraAccordion>
    </Box>
  );
}


function Chip({ choice, onPayload }: ChipProps): ReactElement {
  const choose = () => {
    onPayload(choice);
  };

  const refresh = () => {
    window.localStorage.removeItem("jwt");
    window.localStorage.removeItem("last_timestamp");
    window.location.href = window.location.origin;
  };

  const colorBackground =
    choice === "Skip!" ||
    choice === "Recap!" ||
    choice === "Any" ||
    choice === "All Videos"
      ? "white"
      : choice === "Learning Path"
      ? "#EC0000"
      : "red.400";

  const color =
    choice === "Skip!" ||
    choice === "Recap!" ||
    choice === "Any" ||
    choice === "All Videos"
      ? "red.500"
      : "white";

  return choice === "Refresh!" ? (
    <Box
      as="button"
      p="2"
      ml="0"
      mr="2"
      mt="1"
      maxW="sm"
      borderWidth="1px"
      background={colorBackground}
      borderColor="red.400"
      color={color}
      borderRadius="lg"
      overflow="hidden"
      transition="red"
      onClick={refresh}
      _focus={{
        outline: "none",
        borderColor: "gray.500",
      }}
    >
      {choice}
    </Box>
  ) : (
    <Box
      as="button"
      p="2"
      ml="0"
      mr="2"
      mt="1"
      maxW="sm"
      borderWidth="1px"
      background={colorBackground}
      borderColor="red.400"
      color={color}
      borderRadius="lg"
      overflow="hidden"
      transition="red"
      onClick={choose}
      _focus={{
        outline: "none",
        borderColor: "gray.500",
      }}
    >
      {choice === "Learning Path" ? <b>{choice}</b> : choice}
    </Box>
  );
}

function ListVideos({
  msg,
  title,
  list,
  onPayload,
}: AccordionProps): ReactElement {
  const [choice, setChoice] = useState<string>("");

  const choose = () => {
    onPayload(choice);
  };

  const groupBy = (items: any[], key: string | number) =>
    items.reduce(
      (
        result: { [x: string]: any },
        item: { [x: string]: string | number }
      ) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {}
    );

  var newList = groupBy(msg.list_video_name, "path");
  var paths = Array.from(
    new Set(msg.list_video_name.map((video: any) => video.path))
  );

  return (
    <Box
      p="2"
      mt="-5"
      maxW="xl"
      borderWidth="1px"
      backgroundColor="white"
      borderColor="red.200"
      borderRadius="lg"
      overflow="hidden"
    >
      <Flex>
        <ChakraAccordion
          defaultIndex={0}
          allowToggle
          borderColor="transparent"
          backgroundColor="white"
        >
          <AccordionItem backgroundColor="white">
            <AccordionButton
              as="div"
              color="white"
              _hover={{ color: "white", border: "none" }}
            >
              <Box
                width={"19rem"}
                flex="1"
                textAlign="left"
                fontSize="2xl"
                fontWeight="bold"
                backgroundColor="white"
                color="red.500"
              >
                {msg.content.title[msg.content.title.kind].includes(
                  "All Videos"
                )
                  ? "List of all videos"
                  : msg.content.title[msg.content.title.kind]}
              </Box>
              <a title="Click here to collapse or open the list!">
                {" "}
                <AccordionIcon color="red.500" mr="3" />
              </a>
            </AccordionButton>
            <AccordionPanel p={7} backgroundColor="white">
              <Box>
                {msg.payload === "video_list"
                  ? paths.map((path: any, index: number) => {
                      return (
                        <Box>
                          <Box
                            fontSize="revert"
                            my="2"
                            mt="5"
                            marginLeft="-3"
                            color="red.500"
                          >
                            <b>• {path}:</b>
                          </Box>
                          <Box>
                            {newList[path].map((video: any) => {
                              return (
                                <Box
                                  marginLeft="1"
                                  _hover={{ textDecorationLine: "Underline" }}
                                  color="black"
                                  marginBottom="2"
                                  fontSize="85%"
                                >
                                  <a
                                    title="Click here to open the video!"
                                    href={video.link}
                                    target="_blank"
                                  >
                                    {" "}
                                    <IconButton
                                      colorScheme="red.400"
                                      aria-label="Search database"
                                      size="xxs"
                                      _focus={{
                                        outline: "none",
                                      }}
                                      ml="2"
                                      variant="outline"
                                      icon={<HiExternalLink size="1.1em" />}
                                    />{" "}
                                    {video.name}{" "}
                                  </a>
                                </Box>
                              );
                            })}
                          </Box>
                          <Box marginTop="3" marginBottom="3">
                            <hr />
                          </Box>
                        </Box>
                      );
                    })
                  : msg.folder_list.map((folder: any, index: number) => {
                      return (
                        <Box>
                          <Box
                            marginLeft="-3"
                            _hover={{ textDecorationLine: "Underline" }}
                            color="black"
                            marginBottom="2"
                            fontSize="85%"
                          >
                            <a
                              title="Click here to open the video!"
                              href={folder.link}
                              target="_blank"
                            >
                              {" "}
                              <IconButton
                                colorScheme="red.400"
                                aria-label="Search database"
                                size="xxs"
                                _focus={{
                                  outline: "none",
                                }}
                                ml="2"
                                variant="outline"
                                icon={<HiExternalLink size="1.1em" />}
                              />{" "}
                              <b> {folder.title}</b>
                            </a>
                          </Box>
                        </Box>
                      );
                    })}
              </Box>
            </AccordionPanel>
          </AccordionItem>
        </ChakraAccordion>
      </Flex>
    </Box>
  );
}

function PayloadBotMsg({ msg, onPayload }: Props): ReactElement {
  console.log(msg);
  const choose = (choice: string) => {
    onPayload(msg, choice);
  };

  return (
    <Flex p="4" flexWrap="wrap">
      {msg.payload === "chips" && !msg.rating
        ? msg.values.map((choice: string, i: number) => (
            <Chip key={i} choice={choice} onPayload={choose} />
          ))
        : null}
      {msg.payload === "chips" && msg.rating ? (
        <Flex
          width="full"
          align="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Box textAlign="center">
            <RatingBotMsg onPayload={choose} />
          </Box>
        </Flex>
      ) : null}
      {msg.payload === "accordion" ? (
        <Accordion msg={msg} title={""} list={[]} onPayload={choose} />
      ) : null}
      {msg.payload === "multichoice" ? (
        <MultiChoice msg={msg} title={""} list={[]} onPayload={choose} />
      ) : null}
      {msg.payload === "videos" ? (
        <SearchKeyword msg={msg} title={""} list={[]} onPayload={choose} />
      ) : null}
      {msg.payload === "video_list" || msg.payload === "folder_list" ? (
        <ListVideos msg={msg} title={""} list={[]} onPayload={choose} />
      ) : null}
      <Spacer />
    </Flex>
  );
}

export default PayloadBotMsg;
function baseAssignValue(result: any, key: any, arg2: any[]) {
  throw new Error("Function not implemented.");
}
