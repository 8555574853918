import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import React, { ReactElement } from "react";
import { BiUser } from "react-icons/bi";
import { TextUserMessage } from "../models/Messages";

interface Props {
  msg: TextUserMessage;
}

function TextUserMsg({ msg }: Props): ReactElement {
  console.log(msg);
  return (
    <Flex p="4"
    >
      <Spacer />
      <Box
        p="2"
        maxW="lg"
        ml="4"
        borderWidth="1px"
        borderTopLeftRadius="2xl"
        borderBottomLeftRadius="lg"
        borderBottomRightRadius="2xl"
        overflow="hidden"
        d="flex"
        alignItems="baseline"
        border="1px"
        borderColor="red.700"
        backgroundColor="red.500"
      >
        <Text as="span" top="4px" position="relative">
          <BiUser color="white" size="22px"></BiUser>
        </Text>
        <Text as="span" fontSize="md" fontWeight="bold" color="white">
          You:&nbsp;
        </Text>
        <Text as="span" fontSize="md" color="white">
          {msg.text}
        </Text>
      </Box>
    </Flex>
  );
}

export default TextUserMsg;
