import React from "react";
import root from "react-shadow/emotion";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
interface Props {
  shadow?: boolean;
  chakra?: boolean;
  children: any;
}

export const ShadowChakra = ({
  children,
  shadow = true,
  chakra = true,
}: Props) => {
  return shadow ? (
    <root.div>
      {chakra ? (
        <ChakraProvider>
          <CSSReset />
          {children}
        </ChakraProvider>
      ) : (
        <div>{children}</div>
      )}
    </root.div>
  ) : chakra ? (
    <ChakraProvider>{children}</ChakraProvider>
  ) : (
    <div>{children}</div>
  );
};
