import React, { ReactElement, useState } from "react";
import { Button, Flex, IconButton, Input } from "@chakra-ui/react";
import { BiHelpCircle, BiSend } from "react-icons/bi";
import { IoMdCloseCircleOutline } from "react-icons/io";
//import { Tooltip } from "@material-ui/core";
//import {  makeStyles } from "@material-ui/core/styles";



interface Props {
  onSend: any;
  isDisable: boolean;
}


function TextBox({ onSend, isDisable }: Props): ReactElement {
  const [message, setMessage] = useState<string>();
  const onChange = (event: any) => {
    setMessage(event.target.value);
  };
  const send = (event: any) => {
    if (
      event.type === "click" ||
      (event.type === "keydown" && event.keyCode === 13)
    ) {
      if (message !== "") onSend(message);
      setMessage("");
    }
  };
  const help = () => {
    onSend("help");
  };

  const quit = () => {
    onSend("End");
  };

  /*
  Per il tooltip personalizzato 
  
  const useStyles = makeStyles(theme => ({
    tooltip: {
      backgroundColor: "#DEDEDE",
      color: "#BB0000",
      width: "92px",
      borderRadius: "5px",
      boxShadow: "1px",
    },
    arrow: {
      fontSize: 16,
      width: 17,
      "&::before": {
        backgroundColor: "#DEDEDE",
        boxSizing: "border-box"
      }
  }}));
  const classes = useStyles();

  */

  return (
    <Flex p="2">
      <a title="Click here to end the conversation!">
        {" "}
        <IconButton
       isDisabled={isDisable}
          mr="4"
          backgroundColor="red.500"
          colorScheme="red"
          color="white"
          aria-label="Send"
          icon={<IoMdCloseCircleOutline size="22" />}
          onClick={quit}
          _focus={{
            outline: "none",
            borderColor: "gray.500",
          }}
        />{" "}
      </a>
      <a title="Click here for help!">
        {" "}
        <IconButton
        isDisabled={isDisable}
          mr="4"
          backgroundColor="red.500"
          colorScheme="red"
          color="white"
          aria-label="Send"
          icon={<BiHelpCircle size="22" />}
          onClick={help}
          _focus={{
            outline: "none",
            borderColor: "gray.500",
          }}
        />{" "}
      </a>
      <Input 
      isDisabled={isDisable}
        focusBorderColor="gray.400"
        placeholder=""
        backgroundColor="white"
        value={message}
        onKeyDown={send}
        onChange={onChange}
      />
      <IconButton
      isDisabled={isDisable}
        ml="4"
        backgroundColor="red.500"
        colorScheme="red"
        color="white"
        aria-label="Send"
        icon={<BiSend />}
        onClick={send}
        _focus={{
          outline: "none",
          borderColor: "gray.500",
        }}
      />
    </Flex>
  );
}

export default TextBox;
