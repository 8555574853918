import React, { useEffect, useState } from "react";
import ReactStars from "react-rating-stars-component";
import { FaStar, FaRegStar } from "react-icons/fa";


const RatingBotMsg = ({ onPayload }) => {
  const selectStars = (newValue) => {
    setStars(newValue);
    onPayload(newValue);
  };
  const [stars, setStars] = useState(0);
  const [config, setConfig] = useState({
    size: 43,
    count: 5,
    isHalf: false,
    color: "#7C7C7C",
    activeColor: "#ff0000",
    value: stars,
    outline: "none",
    border: "none",
    emptyIcon: <FaRegStar />,
    filledIcon: <FaStar />,
    onChange: selectStars,
  });
  useEffect(() => {
    setConfig({
      size: 43,
      count: 5,
      isHalf: false,
      color: "#7C7C7C",
      activeColor: "#ff0000",
      value: stars,
      outline: "none",
      border: "none",
      emptyIcon: <FaRegStar/>,
      filledIcon: <FaStar />,
      onChange: selectStars
    });

    return () => {};
  }, []);

  return (
    <div>
        <ReactStars  outline="0px" {...config} />
    </div>
  );
};

export default RatingBotMsg;
