import { Box, Flex, Spacer } from "@chakra-ui/react";
import React, { ReactElement } from 'react'

interface Props {
  msg:string
}

function ErrorBotMsg({msg}: Props): ReactElement {
  return (
    <Flex p="4">
    <Box
      p="2"
      maxW="sm"
      mr="8"
      borderWidth="1px"
      borderColor="red.400"
      borderRadius="lg"
      overflow="hidden"
    >
      
    </Box>
    <Spacer />
  </Flex>
  )
}

export default ErrorBotMsg

