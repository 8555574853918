import React, { ReactElement, useState, useEffect } from "react";
import { Box, Container, Flex, Spinner, Stack } from "@chakra-ui/react";
import axios from "axios";
import API from "../../config/api-config";
import TextBox from "./TextBox";
import Chat from "./Chat";
import {
  Message,
  PayloadBotMessage,
  TextBotMessage,
  TextUserMessage,
} from "./models/Messages";
import TextBotMsg from "./messages/TextBotMsg";
import TextUserMsg from "./messages/TextUserMsg";
import sessionExpired from "../../sessionExpired.json";

function Conversation(): ReactElement {
  const conversation = React.createRef<HTMLDivElement>();
  const lastChat = React.createRef<HTMLElement>();
  const [history, setHistory] = useState<Array<Message>>([]);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [sent, setSent] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(false);

  useEffect(() => {
    console.log("[use effect]");
    // ** START Welcome Message **

    if (history.length === 0) {
      sendMessage({
        message: "WELCOME_EVENT_REACT",
        token: localStorage.getItem("jwt"),
      });
    }

    // ** END **

    if (history.length > 0) {
      const lastMsg = history[history.length - 1];

      if (!lastMsg.isBot) {
        sendMessage({
          message: lastMsg.body.text.text[0],
          token: localStorage.getItem("jwt"),
        });

        /*  sendMessage({
          message: lastMsg.body.text.text[0],
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2NsYWltcyI6eyJlbWFpbCI6InRlc3RAdGVzdC5jb20iLCJyb2xlIjoiYW55IiwibmFtZSI6ImFueSIsImZhbWlseV9uYW1lIjoiYW55IiwiZGlzcGxheV9uYW1lIjoiYW55Iiwic2lnbmF0dXJlIjoiYW55In0sImV4cCI6MTYxNjc4Mzk3Mi4xOTEsInN1YiI6ImFueSJ9.o9osZ1bDpLQvIJgXAtJwbdWtikHHXBeFUcTwzeshpNE",
        }); //token NON valido per TEST */
      }
      if (lastChat.current)
        lastChat.current.scrollIntoView({ behavior: "smooth" });
    }
    return () => {};
  }, [lastChat]);

  useEffect(() => {
    if (!refresh) {
      history.map((x: any) => {
        if (x instanceof PayloadBotMessage && x.payload === "chips") {
          history.splice(history.indexOf(x), 1);
        }
      });
      let msg = new TextBotMessage(sessionExpired.msg);
      let chip = new PayloadBotMessage(sessionExpired.chip);
      setHistory([...history, msg, chip]);

      if (lastChat.current)
        lastChat.current.scrollIntoView({ behavior: "smooth" });

      setIsDisable(true);
    }
  }, [refresh]);

  useEffect(() => {
    setInterval(() => {
      console.log("[inizio timeout]");
      const last_timestamp = localStorage.getItem(
        "last_timestamp"
      ) as unknown as number;
      const now = Date.now();
      const check = (now - last_timestamp) / 1000;
      if (check > 850) {
        //Sostituire con 900
        console.log("[dentro condizione check]");
        setRefresh(false);
      }
    }, 900000);
    return () => {};
  });

  const sendMessage = (data: any) => {
    axios
      //.post("http://localhost:3001/chatbot/es", data)
      // .post("https://generalinext.aisolutions.technology/chatbot/es", data)
      .post(API.dialogFlowAPI, data)
      .then((response) => {
        console.log("[Response]", response);
        const responseData = response.data.message.responses
          .filter((r: any) => {
            return r.message === "text" || r.message === "payload";
          })
          .map((r: any) => {
            r.intent = response.data.message.intent;
            if (r.message === "payload") {
              r.diagnosticInfo = response.data.message.diagnosticInfo;
              r.intent = response.data.message.intent;
            }
            return r.message === "text"
              ? new TextBotMessage(r)
              : new PayloadBotMessage(r);
          });

        //AGGIUNTO LAST TIMESTAMP
        const last_timestamp = Date.now() as unknown as string;
        localStorage.setItem("last_timestamp", last_timestamp);

        setHistory([...history, ...responseData]);
        console.log("HISTORY,", history);
        setSent(false);
      })
      .catch((error) => {
        console.log("error ", error);
        console.log("error response ", error.response);
        console.log("status ", error.response.status);
        if (error.response.status === 401) {
          console.log("Error: ", error);
          window.localStorage.removeItem("jwt");
          window.location.href = window.location.origin;
        }
        console.log("Error: ", error);
      });
  };

  const cleanPayload = (payload?: PayloadBotMessage) => {
    let payloadChoosed = history;
    let p = payload ? payload : payloadChoosed[payloadChoosed.length - 1];

    if (p instanceof PayloadBotMessage && p.payload !== "videos" && !p.rating) {
      //This line leave the result videos on the screen
      while (p instanceof PayloadBotMessage && p.payload !== "accordion") {
        const index = payloadChoosed.indexOf(p);
        if (index > -1) {
          payloadChoosed.splice(index, 1);
        }
        p = payloadChoosed[payloadChoosed.length - 1];
      }
    }
    setHistory(payloadChoosed);
  };

  const usePayload = (payload: PayloadBotMessage, choice: string) => {
    cleanPayload(payload);
    addMessage(choice);
  };

  const addMessage = (msg: string) => {
    const body = { text: { text: new Array<string>() } };
    body.text.text.push(msg);
    cleanPayload();
    let response = new TextUserMessage(body);
    setHistory(history.concat(response));
    setSent(true);
  };

  return (
    <Container maxW="4xl">
      <Flex flexDirection="column">
        <Stack h="80vh" overflow="auto" ref={conversation}>
          {history.map((r, i) => (
            <Chat key={i} msg={r} onPayload={usePayload} />
          ))}
          <article ref={lastChat}>
            {sent ? <Spinner size="lg" ml="4" color="red.500" /> : null}
          </article>
        </Stack>
        <TextBox isDisable={isDisable} onSend={addMessage}></TextBox>
      </Flex>
    </Container>
  );
}

export default Conversation;
